<ng-container *transloco="let t">
  <div class="connectors top position-absolute end-0">
    <svg viewBox="0 0 220 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-secondary mb-2">
      <path id="connector-01" d="M 22000 18 H 50 V 0 H 0 v 50 h 50 V 32 h 21950 z M 36 36 H 14 V 14 h 22 V 36 z"></path>
    </svg>
    <svg viewBox="0 0 220 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-tertiary">
      <path d="M 22000 18 H 50 V 0 H 0 v 50 h 50 V 32 h 21950 z M 36 36 H 14 V 14 h 22 V 36 z"></path>
    </svg>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center w-100 vh-100">
      <div *ngIf="type === undefined" @fadeIn>
        <button class="btn btn-primary" (click)="signInWithGoogle()">
          <fa-icon [fixedWidth]="true" [icon]="faGoogle" class="fa-fw fa-lg"></fa-icon>
          <span class="ms-3">{{ t('sign-in.google.title') }}</span>
        </button>
        <div class="mt-4">
          <button class="btn btn-primary" (click)="type = 'password'">
            <fa-layers [fixedWidth]="true">
              <fa-icon [icon]="faUser" transform="up-2"></fa-icon>
              <fa-layers-text content="***" style="color: white" transform="shrink-2 down-12"></fa-layers-text>
            </fa-layers>
            <span class="ms-3">{{ t('sign-in.password.title') }}</span>
          </button>
        </div>
        <div class="mt-4 email-login">
          <button class="btn btn-primary sign-in-email" (click)="type = 'email'">
            <fa-icon [fixedWidth]="true" [icon]="faEnvelope" class="fa-fw fa-lg"></fa-icon>
            <span class="ms-3">{{ t('sign-in.magic-link.title') }}</span>
          </button>
        </div>
      </div>
      <div class="login p-4 p-md-0">
        <upskill-password-sign-in class="w-100" (closed)="type = undefined" *ngIf="type === 'password'" @fadeIn/>
        <upskill-email-sign-in [email]="email" (closed)="type = undefined" *ngIf="type === 'email'" @fadeIn/>
      </div>
  </div>

  <div class="connectors bottom position-absolute start-0">
    <svg viewBox="0 0 220 50" xmlns="http://www.w3.org/2000/svg" class="fill-secondary mb-2">
      <use href="#connector-01"></use>
    </svg>
    <svg viewBox="0 0 220 50" xmlns="http://www.w3.org/2000/svg" class="fill-tertiary">
      <use href="#connector-01"></use>
    </svg>
  </div>
</ng-container>
