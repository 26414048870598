<ng-container *transloco="let t">
  <div class="password-login">
    <ng-container *ngIf="true">
      <div class="d-flex justify-content-between align-items-center">
        <h5>{{ t('sign-in.password.title') }}</h5>
        <fa-icon [icon]="faXmark" [size]="'lg'" class="close fa-pull-right clickable" (click)="closed.emit()"/>
      </div>

          <div class="alert alert-warning alert-sm" *ngIf="error">
            {{ t('sign-in.password.error') }}
          </div>
          <form>
            <div class="mt-2 mb-3">
              <label>{{ t('sign-in.password.label.email') }}</label>
              <input class="form-control form-control" name="email" type="text" autocomplete="username" [(ngModel)]="email" placeholder="example@domain.com"/>
            </div>
            <div class="mt-2 mb-3">
              <label>{{ t('sign-in.password.label.password') }}</label>
              <input class="form-control form-control" name="password" type="password" autocomplete="current-password" [(ngModel)]="password"/>
            </div>

            <button class="mt-5 btn btn-primary text-nowrap d-flex align-items-center justify-content-center w-100"
                    [disabled]="!email || !password"
                    (click)="signIn()">{{ t('sign-in.password.sign-in') }}</button>
          </form>
    </ng-container>
  </div>
</ng-container>
