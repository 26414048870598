<ng-container *transloco="let t">
  <div class="email-login">

    <ng-container *ngIf="!emailSent">
        <div class="d-flex justify-content-between align-items-center">
          <h5>{{ t('sign-in.magic-link.title') }}</h5>
          <fa-icon [icon]="faXmark" [size]="'lg'" class="close fa-pull-right clickable" (click)="closed.emit()"/>
        </div>
          <p>
            {{ t('sign-in.magic-link.description') }}
          </p>
          <div class="alert alert-warning alert-sm" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <input class="form-control form-control" type="text" [(ngModel)]="email" placeholder="example@domain.com"/>
          <button class="mt-3 btn btn-primary text-nowrap d-flex align-items-center justify-content-center mt-1 w-100"
                  [disabled]="!email"
                  (click)="sendEmailLink()">{{ t('sign-in.magic-link.send') }}</button>
    </ng-container>

    <ng-container *ngIf="emailSent">
      <div class="d-flex justify-content-between align-items-center">
        <h5>{{ t('sign-in.magic-link.title') }}</h5>
        <fa-icon [icon]="faXmark" [size]="'lg'" class="close fa-pull-right clickable" (click)="closed.emit()"/>
      </div>
      <div class="alert alert-success mt-2 mb-3">
        <fa-icon [icon]="faCheckCircle" class="fa-fw fa-lg me-3"></fa-icon>
        <span [innerHTML]="t('sign-in.magic-link.sent.title')"></span>
      </div>
      <p>{{ t('sign-in.magic-link.sent.description') }}</p>
      <p>
        {{ t('sign-in.magic-link.sent.no-mail') }}
        <a (click)="retry()" class="retry-link">{{ t('sign-in.magic-link.sent.retry') }}</a>
      </p>
    </ng-container>

  </div>
</ng-container>
