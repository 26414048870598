import { Component, OnInit } from '@angular/core';
import { Auth, isSignInWithEmailLink, signInWithRedirect } from '@angular/fire/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { faEnvelope, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmailComponent } from './email/email.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { InitialLoaderService } from '../inital-loader/initial-loader.service';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { PasswordComponent } from './password/password.component';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'buildswell-sign-in',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, EmailComponent, PasswordComponent, TranslocoDirective],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SignInComponent implements OnInit {
  protected faEnvelope = faEnvelope;
  protected readonly faGoogle = faGoogle;
  protected readonly faUser = faUser;

  type: string | undefined;
  email = '';

  constructor(private auth: Auth,
              private initialLoaderService: InitialLoaderService) {
  }

  async ngOnInit() {
    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      this.type = 'email';
      return;
    }

    this.initialLoaderService.hide();

    // Parse url since activated snapshot doesn't return params (probably: https://github.com/angular/angular/issues/12157)
    const params = new URLSearchParams(window.location.search);
    if (params.has('email')) { // can be empty
      this.type = 'email';
      this.email = params.get('email') ?? '';
    }
  }

  signInWithGoogle() {
    // Move to top page before signInWithRedirect so that it shows loader after redirect
    // (it isn't shown on /sign-in)
    window.history.pushState(null,'','/');

    // Adding await makes authentication fail for re-sign in after logout (but using new browser window works).
    // (it returns null as idToken in app.component after redirect) for some reason
    signInWithRedirect(this.auth, new GoogleAuthProvider());
  }
}
