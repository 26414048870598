import { Component, EventEmitter, Output } from '@angular/core';
import { Auth, idToken, signInWithEmailAndPassword } from '@angular/fire/auth';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ErrorOverlayComponent } from '../../error-overlay/error-overlay.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { mergeMap, tap } from 'rxjs';
import { TrpcService } from '../../services/trpc.service';
import { LoadingOverlayService } from '../../loading-overlay/loading-overlay.service';

@Component({
  selector: 'upskill-password-sign-in',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, ErrorOverlayComponent, TranslocoDirective],
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {
  @Output() closed = new EventEmitter<void>();

  email = '';
  password = '';
  error = false;

  protected readonly faXmark = faXmark;

  constructor(private overlay: LoadingOverlayService,
      private trpc: TrpcService,
      private auth: Auth) {
  }

  async signIn() {
    try {
      this.overlay.show();
      await signInWithEmailAndPassword(this.auth, this.email, this.password);
      idToken(this.auth)
        .pipe(
          mergeMap(token => this.trpc.client.sso.signIn.mutate(token!)),
          tap(() => location.reload())
        )
        .subscribe();
    } catch {
      this.error = true;
      this.overlay.hide();
    }
  }
}
