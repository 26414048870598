import { Component } from '@angular/core';
import { ErrorOverlayService } from './error-overlay.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'buildswell-error-overlay',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslocoDirective],
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ErrorOverlayComponent {
  faTriangleExclamation = faTriangleExclamation;

  constructor(private errorOverlayService: ErrorOverlayService) {
  }

  get shouldShow(): boolean {
    return this.errorOverlayService.isShown;
  }

  get message(): { title: string; details?: string; } | undefined {
    return this.errorOverlayService.message;
  }
}
