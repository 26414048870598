<ng-container *transloco="let t">
  <div id="error-overlay" class="d-flex" *ngIf="shouldShow" @fadeInOut>
    <div class="w-100 d-flex flex-column justify-content-center align-items-center p-5">
      <h5>
        <fa-icon class="me-1" [icon]="faTriangleExclamation"></fa-icon>
        <span>{{ t('error-overlay.message') }}</span>
      </h5>
      <div class="mt-2" *ngIf="message">
        <div class="fw-bolder" *ngIf="message.title">
          {{message.title}}
        </div>
        <div class="mt-2" *ngIf="message.details">
          {{message.details}}
        </div>
      </div>

    </div>
  </div>
</ng-container>
