import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth, idToken, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from '@angular/fire/auth';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { faCheckCircle, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingOverlayService } from '../../loading-overlay/loading-overlay.service';
import { ErrorOverlayService } from '../../error-overlay/error-overlay.service';
import { ErrorOverlayComponent } from '../../error-overlay/error-overlay.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { mergeMap, tap } from 'rxjs';
import { TrpcService } from '../../services/trpc.service';

@Component({
  selector: 'upskill-email-sign-in',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, ErrorOverlayComponent, TranslocoDirective],
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @Input() email = '';
  @Output() closed = new EventEmitter<void>();


  protected readonly faXmark = faXmark;
  protected faCheckCircle = faCheckCircle;

  emailSent = false;
  errorMessage = '';

  constructor(private router: Router,
              private trpc: TrpcService,
              private auth: Auth,
              private overlay: LoadingOverlayService,
              private errorOverlay: ErrorOverlayService) {
  }

  async ngOnInit() {
    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      await this.signInWithEmail();
      return;
    }
  }

  private async signInWithEmail() {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    try {
      await signInWithEmailLink(this.auth, email!, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      idToken(this.auth)
        .pipe(
          mergeMap(token => this.trpc.client.sso.signIn.mutate(token!)),
          tap(() => this.router.navigate(['/']))
        )
        .subscribe();
    } catch (e) {
      if ((e as any).code === 'auth/invalid-action-code') {
        this.errorOverlay.show({ title: 'Failed to login', details: 'This can happen if email address is not correct, link is expired, or link has already been used.'});
      } else {
        this.errorOverlay.show({ title: 'Failed to login', details: 'Please login again'});
      }

      this.router.navigate(['/sign-in']); // Remove query params so that users can use reload button
    }
  }

  async sendEmailLink() {
    const actionCodeSettings = {
      url: location.href,
      handleCodeInApp: true
    };

    try {
      this.overlay.show();
      await sendSignInLinkToEmail(this.auth, this.email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', this.email);
      this.emailSent = true;
    } catch (e) {
      if ((e as Error).message.includes('auth/invalid-email')) {
        this.errorMessage = 'Invalid email';
      } else {
        this.errorMessage = 'Something went wrong';
      }
      throw e;
    } finally {
      this.overlay.hide();
    }
  }

  retry(): void {
    this.email = '';
    this.errorMessage = '';
    this.emailSent = false;
  }
}
